import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./routes/Home";
import RestaurantDetailPage from "./routes/RestaurantDetailPage";
import { RestaurantsContextProvider } from "./context/RestaurantContext";

const App = () => {
  return (
    <RestaurantsContextProvider>
        <div className="container">
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/restaurants/:id"
                element={<RestaurantDetailPage />}
              />
            </Routes>
          </Router>
        </div>
    </RestaurantsContextProvider>
  );
};

export default App;
