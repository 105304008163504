import React from 'react'

const Header = () => {
  return (
    <div>
        <h1 className='font-weight-light display-1 text-center' style={{fontFamily:"Roboto"}}>Restaurant Finder</h1>
    </div>
  )
}

export default Header